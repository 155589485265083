<template>
	<div class="default-layout full-container">
		<div :class="'side-nav-container' + (state.isSideNavMinimized ? ' minimized' : '')">
			<div class="main-nav full-container">
				<div class="upper-fade" />

				<div class="lower-fade" />

				<div class="logo-container">
					<img 
						class="logo"
						src="/images/BetterPhotoLogo.svg" 
					/>

					<img 
						class="icon"
						src="/images/BetterPhotoIcon.svg" 
					/>
				</div>

				<div class="main-nav-items">
					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_home" />

							<label>Home</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_camera_roll" />

							<label>Photos</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_local_library" />

							<label>Courses</label>
						</div>
					</a>
					
					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_emoji_events" />

							<label>Contests</label>
						</div>
					</a>
					
					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_groups" />

							<label>Community</label>
						</div>
					</a>

					<div class="nav-v-divider" />

					<a class="main-nav-item cta">
						<div class="content">
							<q-icon name="o_camera" />

							<label>BetterPholio</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_login" />

							<label>Sign Up / Log In</label>
						</div>
					</a>

					<div class="nav-v-divider" />

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_info" />

							<label>Help</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_photo_camera_front" />

							<label>About Us</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_contact_support" />

							<label>Contact Us</label>
						</div>
					</a>

					<div class="nav-v-divider" />

					<a class="main-nav-item" style="color: #36E0EC;">
						<div class="content">
							<q-icon name="o_menu_book" />

							<label>Books</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_feed" />

							<label>Newsletter</label>
						</div>
					</a>

					<a class="main-nav-item">
						<div class="content">
							<q-icon name="o_volunteer_activism" />

							<label>Volunteer</label>
						</div>
					</a>

					<div class="nav-v-divider" style="visibility: hidden;" />
				</div>

				<div class="minimize-nav-container">
					<a 
						:class="'icon-container' + (state.isSideNavMinimized ? ' minimized' : '')" 
						:title="(state.isSideNavMinimized ? 'Maximize' : 'Minimize') + ' Navigation Bar'"
						@click="state.isSideNavMinimized = !state.isSideNavMinimized"
					>
						<q-icon name="double_arrow" />
					</a>
				</div>
			</div>
		</div>
		
		<div class="side-nav-filler" />

		<div class="top-nav-container">
			<img 
				class="icon"
				src="/images/BetterPhotoIcon.svg" 
			/>

			<a class="menu-button" @click="state.isSideNavMinimized = !state.isSideNavMinimized">
				<q-icon name="menu" />
			</a>
		</div>

		<div class="main-container">
			<div class="view-container">
				<router-view />
			</div>

			<div class="main-footer">
				<div class="copyright">
					<div>Copyright &copy; 1996-2021 BetterPhoto Inc &reg;</div>
					<div>All Rights Reserved</div>
				</div>

				<div class="bottom-nav-items">
					<a class="bottom-nav-item">
						Terms and Conditions
					</a>

					<q-icon name="circle" />

					<a class="bottom-nav-item">
						Privacy Policy
					</a>

					<q-icon name="circle" />

					<a class="bottom-nav-item">
						Cookie Policy
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
	name: 'DefaultLayout',

	setup() {
		const state = reactive({
			isSmallFormFactor: false,
			isSideNavMinimized: false
		});	

		return { state }
	}
})
</script>

<style lang="scss" scoped>
.default-layout {
	display: flex;
	position: relative;

	@media only screen and (max-width: 480px) {
		flex-direction: column;
	}

	.side-nav-container {
		display: flex;
		width: 240px;
		min-width: 240px;
		height: 100%;
		z-index: 1;
		overflow: hidden;
		//border: 1px solid #f00;
		transition: width 1s, min-width 1s;

		@media only screen and (max-width: 1150px) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
		
		@media only screen and (max-width: 480px) {
			top: 48px;
			height: calc(100% - 48px);
			width: 100%;
			min-width: 100%;
		}

		.main-nav {
			overflow: hidden;
			flex-direction: column;
			background-color: #1d4d76;
			border-right: 1px solid rgba(#fff, 0.05);
			position: relative;

			.logo-container {
				display: grid;
				flex-shrink: 0;
				justify-content: center;
				justify-items: center;
				align-items: center;
				width: 100%;
				height: 75px;
				z-index: 2;
				//border: 1px solid #f0f;

				@media only screen and (max-width: 480px) {
					display: none;
				}

				.logo {
					grid-row: 1;
					grid-column: 1;
					width: 85%;
					filter: drop-shadow(1px 1px 3px rgba(#000, 0.5));
					transform: rotateY(0deg);
					transform-origin: center;
					transition: transform 0.7s 0.7s ease-in;
				}

				.icon {
					grid-row: 1;
					grid-column: 1;
					height: 36px;
					transform-style: preserve-3d;
					transform: rotateY(90deg);
					transform-origin: center;
					transition: transform 0.7s ease-in;
				}
			}

			.main-nav-items {
				display: flex;
				flex-direction: column;
				flex: 1;
				//border: 1px solid #f00;
				overflow-y: auto;
				overflow-x: hidden;

				@media only screen and (max-width: 480px) {
					margin-top: 20px;
				}

				> * {
					margin-top: 4px;
					overflow: hidden;
					flex-shrink: 0;
				}

				.main-nav-item {
					display: flex;
					align-items: center;
					padding: 3px 0;
					font-family: Comfortaa;
					font-size: 16px;
					color: rgba(#fff, 0.5);
					text-transform: lowercase;
					cursor: pointer;
					//border: 1px solid #f00;
					white-space: nowrap;
					transition: padding 0.7s ease-in;

					* {
						cursor: pointer;
					}

					.content {
						display: flex; 
						align-items: center; 
						width: calc(100% - 32px); 
						height: 36px; 
						margin: auto; 
						border-radius: 4px; 
						padding: 0 8px; 
						background-color: rgba(#000, 0);
						overflow: hidden;
						white-space: nowrap;
						transition: width 0.7s ease-in, background-color 0.5s ease-in;

						.q-icon {
							font-size: 28px;
							transition: color 0.7s ease-in;
						}

						label {
							display: flex;
							flex: 1;
							//border: 1px solid #f00;
							overflow: hidden;
							white-space: nowrap;
							margin-left: 8px;
							transition: all 0.7s ease-in; //flex 0.7s ease-in, margin-left 0.7s ease-in;
						}
					}
				}

				.main-nav-item.cta {
					color: rgba(#fff, 0.7);
					
					.content {
						background: linear-gradient(135deg, #E6A01F 0%, #F16719 100%);
						filter: drop-shadow(1px 1px 3px rgba(#000, 0.25));
						border: 1px solid rgba(#E7A01F, 0.8);

						label {
							font-weight: bold;
						}
					}
				}

				.main-nav-item.cta:hover {
					filter: brightness(1.1);
				}

				.main-nav-item:hover {
					color: rgba(#fff, 0.9);

					.content {
						background-color: rgba(#000, 0.2);
					}
				}

				.nav-v-divider {
					display: flex;
					width: 90%;
					border-top: 1px solid rgba(#fff, 0.05);
					margin: 12px auto;
				}
			}

			.minimize-nav-container {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				height: 40px;
				//border: 1px solid #f00;
				
				@media only screen and (max-width: 480px) {
					display: none;
				}

				.icon-container {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48px;
					height: 40px;
					transform: rotateY(180deg);
					cursor: pointer;
					//background-color: #00f;
					transition: transform 1s;
					z-index: 2;

					.q-icon {
						font-size: 18px;
						color: #E7A01F;
					}
				}

				.icon-container:hover {
					filter: brightness(120%);
				}

				.icon-container.minimized {
					transform-style: preserve-3d;
					transform: rotateY(0);
				}
			}

			.upper-fade {
				display: flex;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 87px;
				//border: 1px solid #000;
				//background: linear-gradient(180deg, #f00 0, #f00 85%, rgba(#f00, 0) 100%);
				background: linear-gradient(180deg, #1d4d76 0, #1d4d76 85%, rgba(#1d4d76, 0) 100%);
				z-index: 2;
				//border: 1px solid #f00;

				@media only screen and (max-width: 480px) {
					height: 35px;
				}
			}

			.lower-fade {
				display: flex;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 73px;
				//border: 1px solid #000;
				background: linear-gradient(0, #1d4d76 0, #1d4d76 80%, rgba(#1d4d76, 0) 100%);
				z-index: 1;
				//border: 1px solid #f00;

				@media only screen and (max-width: 480px) {
					height: 25px;
				}
			}
		}
	}

	.side-nav-container.minimized {
		width: 48px;
		min-width: 48px;

		@media only screen and (max-width: 480px) {
			width: 0;
			min-width: 0;
		}

		.main-nav {
			.logo-container {
				.logo {
					transform: rotateY(90deg);
					transition: transform 0.7s ease-out;
				}

				.icon {
					transform:  rotateY(180deg);
					transition: transform 0.7s 0.7s ease-out;
				}
			}

			.main-nav-items {
				.main-nav-item {
					transition: padding 0.7s ease-out;

					.content {
						width: calc(100% - 8px); 
						padding: 0; 
						justify-content: center;
						transition: width 0.7s ease-out, width 0.7s ease-out;
						//border: 1px solid #f00;

						.q-icon {
							transition: all 0.7s ease-out;
						}

						label {
							flex: 0;
							margin-left: 0;
							transition: flex 0.7s ease-out, margin-left 0.7s ease-out;
						}
					}
				}
			}
		}
	}

	.side-nav-filler {
		display: none;
		flex-shrink: 0;
		width: 48px;
		height: 100%;
		//border: 1px solid #f00;

		@media only screen and (max-width: 1150px) {
			display: flex;
		}

		@media only screen and (max-width: 480px) {
			display: none;
		}
	}

	.top-nav-container {
		display: none;
		flex-shrink: 0;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 52px;
		padding: 8px 16px;
		background-color: #1d4d76;
		filter: drop-shadow(1px 0 2px rgba(#000, 0.5));
		z-index: 4;
		//border: 1px solid #f00;

		@media only screen and (max-width: 480px) {
			display: flex;
		}

		.icon {
			height: 80%;
			filter: drop-shadow(1px 1px 3px rgba(#000, 0.5));
		}

		.menu-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 34px;
			height: 34px;
			//border: 1px solid rgba(#000, 0.1);
			border-radius: 4px;
			background-color: #194164;
			//filter: drop-shadow(0 0 3px rgba(#000, 0.2));
			//filter: brightness(70%);

			.q-icon {
				font-size: 20px;
				color: rgba(#fff, 0.7);
			}
		}
	}

	.main-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
		//border: 1px solid #f00;

		.view-container {
			display: flex;
			height: 100%;
			//border: 1px solid #f0f;
		}

		.main-footer {
			display: flex;
			flex-flow: row wrap-reverse;
			justify-content: space-evenly;
			align-items: center;
			text-align: center;
			//border: 1px solid #f00;
			padding: 3px 16px;

			.bottom-nav-items {
				display: flex;
				justify-content: center;
				align-items: center;
				//padding: 4px 16px;

				@media only screen and (max-width: 410px) {
					flex-direction: column;
				}

				.bottom-nav-item {
					display: inline-block;
					font-size: 10px;
					font-family: Comfortaa;
					text-transform: uppercase;
					color: rgba(#fff, 0.4);
					cursor: pointer;
				}

				.bottom-nav-item:hover {
					color: rgba(#fff, 0.8);
				}

				.q-icon {
					display: inline-block;
					font-size: 6px;
					color: rgba(#fff, 0.25);
					margin: 0 16px;

					@media only screen and (max-width: 410px) {
						display: none;
					}
				}
			}

			.copyright {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				align-items: center;
				//padding: 4px 12px;
				color: rgba(#fff, 0.2);
				font-size: 10px;
				letter-spacing: 2px;
				text-transform: uppercase;

				> div  {
					display: inline-block;
					padding: 0 4px;
				}
			}
		}
	}
}
</style>
